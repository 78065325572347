export function truncateString(text?: string | null, maxLength = 255) {
  return text?.length > maxLength ? text.substring(0, maxLength) + "..." : text;
}

export function defineSeo<T extends { title?: string | null; abstract?: string | null }>(data: T) {
  const siteConfig = useSiteConfig();

  useSeoMeta({
    title: data?.title || siteConfig.title,
    description: data?.abstract || siteConfig.description,
    ogTitle: data?.title || siteConfig.title,
    ogDescription: data?.abstract || siteConfig.description,
    twitterTitle: data?.title || siteConfig.title,
    twitterDescription: data?.abstract || siteConfig.description,
    twitterCard: "summary_large_image",
  });

  defineOgImageComponent("NuxtSeo", {
    theme: "#D1E7F9",
    colorMode: "light",
    siteLogo: "/logo-omnia.svg",
    title: truncateString(data?.title || siteConfig.title, 15),
    description: data?.abstract || siteConfig.description,
  });
}
